import React from "react";

import { Button } from "djinn-components";
import FormHolder from "../FormHolder";
const TransferProject = () => {
  return (
    <FormHolder>
      <div className="bg-gray-600 bg-opacity-50 rounded-xl shadow-sm p-10 flex flex-col justify-center items-center text-center">
        <h5 className="text-xl text-white font-medium">Transfer Project</h5>
        <p className="w-2/3 mb-4 mt-2 text-gray-450 text-sm font-light">
          You can transfer a project to another account. This action cannot be
          undone
        </p>
        <div className="w-32 opacity-50">
          <Button text="Coming soon" disabled={true} />
        </div>
      </div>
    </FormHolder>
  );
};

export default TransferProject;
