import { WarningIcon } from "djinn-components";
import React from "react";
import { useProjectState } from "../../utils/requiresProject";

const EditingOldVersionWarning: React.FC = () => {
  const { project } = useProjectState();

  if (project.version_status != "PREVIOUS") return null;

  return (
    <div className="p-4 max-w-5xl mx-auto bg-gray-500 mt-10 rounded-lg flex items-center">
      <WarningIcon size={40} className="w-10 h-10 block text-orange-400" />

      <div className="ml-4">
        <h3 className="font-semibold">WARNING</h3>
        <p className="text-sm font-light text-gray-400">
          Your are editing an old version of your documentation, make sure this
          is intentional.
        </p>
      </div>
    </div>
  );
};

export default EditingOldVersionWarning;
