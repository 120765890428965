import React from "react";
import { TickCircle } from "djinn-components";

export default function RegisterDetailsSidebar() {
  return (
    <div className="h-full bg-gray-850 w-2/6 max-w-md rounded-r-xl shadow-lg p-4 flex justify-center items-center flex-col flex-shrink-0">
      <div className="flex justify-start text-white px-8 mb-8">
        <TickCircle className="w-8 h-8 mr-3 flex-shrink-0 text-green-600" />
        <div className="pt-1">
          <h2 className="text-lg font-medium">Get started quickly</h2>
          <p className="text-sm font-light opacity-50">
            Create a new project right away and create beautiful API
            Documentation
          </p>
        </div>
      </div>

      <div className="flex justify-start text-white px-8 mb-8">
        <TickCircle className="w-8 h-8 mr-3 flex-shrink-0 text-green-600" />
        <div className="pt-1">
          <h2 className="text-lg font-medium">Multiple editors</h2>
          <p className="text-sm font-light opacity-50">
            Add as many users as you like to your account allowing your entire
            team to manage your documentation
          </p>
        </div>
      </div>

      <div className="flex justify-start text-white px-8">
        <TickCircle className="w-8 h-8 mr-3 flex-shrink-0 text-green-600" />
        <div className="pt-1">
          <h2 className="text-lg font-medium">Full text search</h2>
          <p className="text-sm font-light opacity-50">
            Users will be able to search documentation seamlessly, with our
            advanced search
          </p>
        </div>
      </div>
    </div>
  );
}
