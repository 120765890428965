import React from "react";
import { RiPencilFill } from "react-icons/ri";
import { UserModel } from "../../../types/models";
import { useProjectState } from "../../../utils/requiresProject";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import { Button } from "djinn-components";
import axios from "../../../plugins/axios";
import { useAlert } from "react-alert";
const EditProjectUserModal: React.FC<{
  user: UserModel;
  onComplete: () => void;
}> = ({ user, onComplete }) => {
  const { project, reloadProject } = useProjectState();

  const deleteUser = useConfirmDelete();
  const alert = useAlert();

  const submit = async (e: any) => {
    e.preventDefault();
    // await switchUserRole(role);
    // setShowUserOptions(null);
  };

  const deleteUserById = async () => {
    try {
      await axios.delete(`projects/${project.id}/users/${user.id}`);
      reloadProject();
      onComplete();
    } catch (error) {
      alert.show("Failed to delete user", { type: "error" });
    }
  };

  return (
    <>
      <form className="p-8 px-14 pt-3" onSubmit={submit}>
        <div>
          <div className="mx-auto flex items-center justify-center h-8 w-8">
            <RiPencilFill size={16} color="white" />
          </div>
          <div className="text-center mt-2">
            <h3
              className="text-lg leading-6 font-medium text-white"
              id="modal-headline"
            >
              Manage User
            </h3>
            <p className="leading-5 font-light text-xs text-gray-400">
              Edit {user.name}'s settings for project below
            </p>

            {/* <div className="mt-8 relative">
              <SelectField
                selected={role}
                label="Role Select"
                options={[
                  { value: "admin", key: "Admin" },
                  { value: "user", key: "User" },
                ]}
                onSelect={(v) => {
                  setRole(v);
                }}
              />
            </div> */}
          </div>
        </div>
        {/* <div className="flex mt-8">
          <Button
            text="Save Changes"
            type="submit"
            buttonStyle="green"
            loading={false}
            disabled={false}
          />
        </div> */}
        <div className="flex mt-3">
          <Button
            text="Delete user"
            type="button"
            buttonStyle="red"
            onClick={() => deleteUser("DELETE USER", deleteUserById)}
          />
        </div>
      </form>
    </>
  );
};

export default EditProjectUserModal;
