import React from "react";
import { Link, Route, Routes } from "react-router-dom";
import AccountManagerLayout from "../../layouts/account";
import { settingsRoutes } from "../../utils/account.utils";

const ManageAccountPage: React.FC = () => {
  return (
    <AccountManagerLayout>
      <Routes>
        {settingsRoutes.map(({ path, Component }: any) => (
          <Route
            path={path}
            element={
              <>
                <div className="border-b border-gray-750 mb-10">
                  <div className="max-w-5xl mx-auto flex py-4">
                    {settingsRoutes.map(
                      ({ path: crPath, title: crTitle }: any) => (
                        <Link
                          to={`/account/${crPath}`}
                          className={`${
                            crPath === path ? "text-white" : "text-gray-450"
                          }  hover:text-white mr-4`}
                        >
                          {crTitle}
                        </Link>
                      )
                    )}
                  </div>
                </div>
                <Component />
              </>
            }
          ></Route>
        ))}
      </Routes>
    </AccountManagerLayout>
  );
};

export default ManageAccountPage;
