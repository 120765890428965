import React, { ComponentType, FunctionComponent } from "react";
import { useAuth } from "./useAuth";
import { useRoutes } from "react-router-dom";
import { unauthenticatedRoutes } from "../../routes";
import LoadingComponent from "../../components/LoadingComponent";

export function requiresAuth<T extends object>(Component: ComponentType<T>) {
  const AppWithAuthenticator: FunctionComponent<T> = (props) => {
    const routerElement = useRoutes(unauthenticatedRoutes);
    const { user, loading } = useAuth();

    if (loading || user === undefined)
      return (
        <div className="bg-black w-full h-full top-0 left-0 fixed">
          <LoadingComponent />
        </div>
      );

    if (user === null) return <>{routerElement}</>;
    return <Component {...props} />;
  };

  return AppWithAuthenticator;
}
