import React from "react";
import HelpLink from "../../HelpLink";
import { CheckboxInput } from "djinn-components";

const ProjectSettingsLayout: React.FC<any> = () => {
  return (
    <>
      {" "}
      <div
        className="w-full h-full flex justify-center items-center text-white"
        style={{ height: "560px" }}
      >
        <label className="rounded-3xl bg-gray-700 px-6 py-8 mr-4 flex flex-col justify-center items-center cursor-pointer hover:bg-opacity-75 transition ease-in-out duration-150">
          <h5>Docbloc Basic</h5>
          <img
            alt="Docbloc layout basic"
            src="/images/project-layout-basic.svg"
          />
          <CheckboxInput id="dark_mode" value={true} onChange={() => {}} />
          <p className="text-xs mt-6">Show preview</p>
        </label>
        <label className="rounded-3xl px-6 py-8 ml-4 flex flex-col justify-center items-center cursor-not-allowed opacity-50">
          <h5>Docbloc Pro (coming soon)</h5>

          <img src="/images/project-layout-pro.svg" alt="Docbloc layout pro" />
          <CheckboxInput id="light_mode" value={false} onChange={() => {}} />
          <p className="text-xs mt-6">Show preview</p>
        </label>
      </div>
      <HelpLink
        text="Need help with your Docbloc layout?"
        link="guides/design-and-layout"
      />
    </>
  );
};

export default ProjectSettingsLayout;
