import React from "react";
import Header from "../../components/Header";
import OpenApiImport from "./open-api";
import PostmanCollectionImport from "./postman";
import { Link, useParams } from "react-router-dom";
import { SiOpenapiinitiative, SiPostman } from "react-icons/si";
import AccountManagerLayout from "../../layouts/account";

const activeStyle =
  "flex items-center justify-center whitespace-nowrap px-14 py-3 text-xs bg-green-600 rounded-full text-white pointer cursor-pointer";

const inactiveStyle =
  "flex items-center justify-center whitespace-nowrap px-14 py-3 text-xs text-gray-400 cursor-pointer hover:text-white";

export default function ImportPage() {
  const params = useParams<any>();

  return (
    <AccountManagerLayout>
      <main className="flex-1 relativez-0 overflow-y-auto p-8">
        <Header
          title="Project Import"
          subtitle="Docbloc gives you the ability to import content from the following platforms, please choose one below to get started:"
        />
        <div className="max-w-5xl mx-auto mt-10">
          <div className="w-full flex items-center justify-center mb-6">
            <div className="bg-gray-500 rounded-full flex mt-1 max-w-min">
              <Link
                to="/import/open-api"
                className={
                  params.type == "open-api" ? activeStyle : inactiveStyle
                }
              >
                <SiOpenapiinitiative size={18} className="mr-2" /> Open Api
              </Link>
              <Link
                to="/import/postman"
                className={
                  params.type == "postman" ? activeStyle : inactiveStyle
                }
              >
                <SiPostman size={18} className="mr-2" />
                Postman
              </Link>
            </div>
          </div>

          {params.type == "open-api" ? (
            <OpenApiImport />
          ) : (
            <PostmanCollectionImport />
          )}
        </div>
      </main>
    </AccountManagerLayout>
  );
}
