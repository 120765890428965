import React from "react";
import { PaymentMethodModel } from "../../types/models";

const BillingCodeBlock: React.FC<{
  card: PaymentMethodModel;
  onClick: (id: number) => void;
}> = ({ card, onClick }) => {
  const getIcon = (method: string) => {
    switch (method) {
      case "visa":
        return "https://cdn.visa.com/v2/assets/images/logos/visa/blue/logo.png";
      default:
        return "";
    }
  };

  return (
    <div
      className="border bg-white cursor-pointer rounded-lg p-4 shadow mb-4 transition ease-in-out duration-150 text-gray-900"
      onClick={() => onClick(card.id)}
    >
      <div className="flex items-start justify-between">
        <img
          src={getIcon(card.payment_method)}
          className="w-16 mb-2"
          alt={card.payment_method}
        />
        {card.default && (
          <span className="text-green-600 font-bold text-xs">DEFAULT</span>
        )}
      </div>
      <h4 className="font-base my-3 text-lg">{card.name}</h4>
      <p className="text-md font-medium">**** **** **** {card.last4}</p>
      <p className="text-sm font-medium">Expires: {card.expires}</p>
    </div>
  );
};

export default BillingCodeBlock;
