import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import EditingOldVersionWarning from "../../../components/EditingOldVersionWarning";
import Header from "../../../components/Header";
import ProjectSettingsSidebar from "../../../components/Sidebar/SettingsSidebar";
import settingsRoutes from "./paths";

const SettingsPage: React.FC<any> = () => {
  const location = useLocation();

  const [activePath, setActivePath] = useState(`${location.pathname}`);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="flex items-start justify-start">
      <ProjectSettingsSidebar activePath={activePath} />
      <div className="w-full pb-10 h-screen overflow-auto ">
        <EditingOldVersionWarning />

        <Routes>
          {settingsRoutes
            .map((sr) => sr.children)
            .reduce((current = [], group = []) => [...group, ...current])
            .map(({ path: routePath, subtitle, Component, title }, index) => (
              <Route
                path={`${routePath}`}
                key={`${index}`}
                element={
                  <>
                    <Header title={title} subtitle={subtitle} />
                    <Component />
                  </>
                }
              ></Route>
            ))}
        </Routes>
      </div>
    </div>
  );
};

export default SettingsPage;
