import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "djinn-components";

const h1Style = "text-2xl font-medium mb-2";
const pStyle = "text-center text-gray-450 text-sm mb-10";

const SuccessScreen: React.FC<any> = () => {
  const params = useParams<any>();
  const navigate = useNavigate();

  const DefaultComp = ({ title, subtitle, buttnText, url }: any) => {
    return (
      <>
        <h1 className={h1Style}>{title}</h1>
        <p className={pStyle}>{subtitle}</p>
        <div className="w-44">
          <Button
            onClick={() => navigate(url)}
            buttonStyle="green"
            text={buttnText}
          />
        </div>
      </>
    );
  };

  const getContent = () => {
    switch (params.type) {
      case "email-sent":
        return (
          <DefaultComp
            title="Password reset link sent"
            subtitle="Please check your email for the reset link, if your email is not received within 5 minutes, check your spam folder"
            buttnText="Back to Sign in"
            url="/"
          />
        );
      case "password-reset":
        return (
          <DefaultComp
            title="Password successfully reset"
            subtitle="Your password is now reset and can proceed to sign in with your new password"
            buttnText="Back to Sign in"
            url="/"
          />
        );
      case "join-team":
        return (
          <DefaultComp
            title="Invite Accepted"
            subtitle="Your Docbloc invitation has been accepted, you will need to login to access your new account."
            buttnText="Go to Sign in"
            url="/"
          />
        );
      default:
        return (
          <DefaultComp
            title="Success"
            subtitle="All good"
            buttnText="Back to Dashboard"
            url="/choose-project"
          />
        );
    }
  };

  return (
    <div className="w-screen h-screen bg-gray-1000 text-white flex flex-col items-center justify-center">
      <div className="w-3/12 flex flex-col items-center justify-center">
        <a href="https://docbloc.co" target="_blank" rel="noopener noreferrer">
          <img src="/full-logo.svg" alt="Docbloc logo" className="h-8 mb-10" />
        </a>
        {getContent()}
      </div>
    </div>
  );
};

export default SuccessScreen;
