import React from "react";
import { Link, useParams } from "react-router-dom";
import dateFormat from "dateformat";
import { useProjectState } from "../../../../utils/requiresProject";

const EditDashboard: React.FC<any> = ({ setShowCreateItem }) => {
  const { project } = useProjectState();
  const params = useParams<any>();

  const getLink = (item: any) => {
    switch (item.content_model) {
      case "ProjectEndpoint":
        return `/project/${params.project}/edit/endpoint/${item.id}`;
      case "ProjectContentPage":
        return `/project/${params.project}/edit/page/${item.id}`;
      case "ProjectGroup":
        return `/project/${params.project}/edit/group/${item.id}`;
      default:
        return `/project/${params.project}`;
    }
  };

  return (
    <div className="overflow-auto max-w-5xl mx-auto pt-10">
      <h1 className="text-xl text-gray-400 font-light">
        Editing Project:{" "}
        <strong className="font-medium">
          {project.project.name}{" "}
          {project.project.features.includes("versioning")
            ? `(${project.name})`
            : ""}
        </strong>
      </h1>
      <h2 className="text-gray-450 text-sm font-light">
        Click an item on the left to get started or{" "}
        <a
          className="font-bold hover:text-white cursor-pointer"
          onClick={() => setShowCreateItem(true)}
        >
          create a new item
        </a>
      </h2>
      <div className="bg-gray-700 rounded-xl w-2/4 h-96 p-6 mt-10 bg-opacity-50">
        <h3 className="font-medium text-white mb-6 mt-4">Continue editing:</h3>

        {project.latest_3.map((recent: any, index: any) => (
          <Link
            className="block mt-4 p-4 rounded-xl bg-gray-700 hover:bg-gray-800 cursor-pointer"
            to={getLink(recent)}
            key={index}
          >
            <p className="text-sm font-medium">
              {recent.name} {recent.method ? `(${recent.method})` : ""}
            </p>
            <p className="text-xs font-light mt-1 text-gray-300">
              Last edited:{" "}
              <strong className="text-white">
                {dateFormat(recent.updated_at, "d mmm, h:MM: TT")}
              </strong>
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default EditDashboard;
