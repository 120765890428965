import React from "react";

const FormHolder = ({ children }: any) => {
  return (
    <div className="mt-10 max-w-5xl mx-auto">
      <div className="bg-gray-600 bg-opacity-50 rounded-xl overflow-auto p-10 flex-shrink-0 w-full shadow-sm">
        {children}
      </div>
    </div>
  );
};

export default FormHolder;
