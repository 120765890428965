import React, { useState } from "react";
import ProjectDashboardAnalytics from "./Analytics";
import ProjectDashboardUsers from "./ProjectUsers";
import ProjectDashboardInfo from "./ProjectInformation";
import { isSetupCompleted } from "./ProjectDashboard.utils";
import ProjectLaunchpad from "./ProjectLaunchpad";
import { useProjectState } from "../../utils/requiresProject";
import EditingOldVersionWarning from "../EditingOldVersionWarning";

const ProjectDashboard: React.FC = () => {
  const { project } = useProjectState();
  const [setupCompleted] = useState(isSetupCompleted(project));

  if (!setupCompleted) return <ProjectLaunchpad />;
  return (
    <>
      <EditingOldVersionWarning />

      <div className="pt-10 max-w-5xl mx-auto grid grid-cols-5 gap-4">
        <div className="w-full h-full flex flex-col flex-shrink-0 col-span-3">
          <ProjectDashboardInfo />
          <div className="h-6 flex-shrink-0"></div>
          <ProjectDashboardUsers />
        </div>
        <ProjectDashboardAnalytics />
      </div>
    </>
  );
};

export default ProjectDashboard;
