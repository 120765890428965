import React, { useState } from "react";
import ParameterFieldDetailsWindow from "./DetailsWindow";
import { Button } from "djinn-components";

const EditItemModal: React.FC<any> = ({ onResolve, param }) => {
  const [loading, setLoading] = useState(false);
  const [theParam, setTheParam] = useState(param);

  const updateParamValue = (val: any, key: number) => {
    const newObj = JSON.parse(JSON.stringify(theParam));
    newObj[key] = val;
    setTheParam(newObj);
  };

  const onResolveIn = (value: number | false) => {
    if (value != false) {
      setLoading(true);
      onResolve(value);
    } else {
      setLoading(true);
      onResolve(param);
    }
  };

  return (
    <div className="absolute bg-gray-750 w-full h-full top-0 left-0 z-10 rounded-md p-6 flex flex-col items-center justify-center">
      <div className="w-3/4 mx-auto">
        <ParameterFieldDetailsWindow
          item={theParam}
          updateParamValue={updateParamValue}
        />
      </div>
      <div className="flex items-center justify-center">
        <Button
          type="button"
          onClick={() => onResolveIn(false)}
          disabled={loading}
          buttonStyle="red"
          loading={loading}
          text="Cancel"
        />
        <div className="w-10 h-10"></div>
        <Button
          type="button"
          buttonStyle="green"
          onClick={() => onResolveIn(theParam)}
          disabled={loading || !theParam.name || !theParam.description}
          loading={loading}
          text="Save changes"
        />
      </div>
    </div>
  );
};

export default EditItemModal;
