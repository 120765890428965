import React, { useState } from "react";
import { Modal, Button, TickIcon } from "djinn-components";
import UpgradeProjectModal from "../../../components/Modals/UpgradeProjectModal";
import { Elements } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import { useProjectState } from "../../../utils/requiresProject";
import { stripePromise } from "../../../utils/stripe.utils";

const ProjectUpgradePage: React.FC = () => {
  const { project } = useProjectState();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  return (
    <>
      <div className="mt-10 max-w-5xl mx-auto bg-gray-600 rounded-lg flex items-end justify-between w-full">
        <div className="px-10 py-20">
          <h3 className="text-2xl font-medium max-w-lg mb-10">
            In addition to the great existing features, Docbloc Pro now let’s
            you:
          </h3>

          <div className="font-light text-sm">
            <div className="flex items-center justify-start my-4">
              <TickIcon className="text-green-600 w-4 h-4 mr-3" size={18} />{" "}
              Project versioning
            </div>
            <div className="flex items-center justify-start my-4">
              <TickIcon className="text-green-600 w-4 h-4 mr-3" size={18} />{" "}
              Unlimited users with granular control over access and permissions
            </div>
            <div className="flex items-center justify-start my-4">
              <TickIcon className="text-green-600 w-4 h-4 mr-3" size={18} />{" "}
              Extra customizations to fonts, colours and layouts
            </div>
            <div className="flex items-center justify-start my-4">
              <TickIcon className="text-green-600 w-4 h-4 mr-3" size={18} />{" "}
              Advanced analytics and user activity logging
            </div>
            <div className="flex items-center justify-start my-4">
              <TickIcon className="text-green-600 w-4 h-4 mr-3" size={18} />{" "}
              Custom project domain and documentation features
            </div>
            <div className="flex items-center justify-start my-4">
              <TickIcon className="text-green-600 w-4 h-4 mr-3" size={18} />{" "}
              Added content types and UI features
            </div>
          </div>
          {project.project.type == "standard" ? (
            <>
              <div className="w-32 mt-10">
                <Button
                  text="Upgrade Now"
                  onClick={() => setShowUpgradeModal(!showUpgradeModal)}
                />
              </div>
            </>
          ) : (
            <div className="w-72 mt-10">
              <Button
                text="Your project is currently on the Pro plan"
                disabled
              />
            </div>
          )}

          {project.project.type == "standard" ? (
            <a
              href="https://docbloc.co/pricing"
              target="_blank"
              rel="noopener noreferrer"
              className="mt-10 block text-sm font-extralight underline"
            >
              View pro pricing details
            </a>
          ) : (
            <Link to="/account/billing">
              <a className="mt-10 block text-sm font-extralight underline">
                Manage your Billing & Payments
              </a>
            </Link>
          )}
        </div>
        <div className="max-w-md">
          <img src="/project-upgrade.png" alt="Project Upgrade" />
        </div>
      </div>
      <Modal
        shown={showUpgradeModal}
        dismissible={true}
        onClose={() => setShowUpgradeModal(false)}
      >
        <Elements stripe={stripePromise}>
          <UpgradeProjectModal
            onClose={() => setShowUpgradeModal(false)}
            projectId={project.project.id}
            projectVersionId={project.id}
          />
        </Elements>
      </Modal>
    </>
  );
};

export default ProjectUpgradePage;
