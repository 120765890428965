import React from "react";

export default function ProjectSettingsIcon({ className = "" }) {
  return (
    <span className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 19 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.42681 2.91968H18.5197C19.16 2.91968 19.16 1.94645 18.5197 1.94645H7.42681C7.20419 0.83623 6.23492 0 5.07385 0C3.91277 0 2.9435 0.83623 2.72088 1.94645H1.23216C0.591883 1.94645 0.591883 2.91968 1.23216 2.91968H2.72088C2.9435 4.0299 3.91277 4.86613 5.07385 4.86613C6.23492 4.86613 7.20419 4.0299 7.42681 2.91968ZM17.0311 7.78462H18.5198C19.1601 7.78462 19.1601 6.8114 18.5198 6.8114H17.0311C16.8085 5.70117 15.8392 4.86494 14.6781 4.86494C13.517 4.86494 12.5478 5.70117 12.3251 6.8114H1.23222C0.591937 6.8114 0.591937 7.78462 1.23222 7.78462H12.3251C12.5478 8.89484 13.517 9.73107 14.6781 9.73107C15.8392 9.73107 16.8085 8.89484 17.0311 7.78462ZM18.5198 12.654H11.2686C11.0459 13.7642 10.0767 14.6005 8.91558 14.6005C7.75451 14.6005 6.78524 13.7642 6.56261 12.654H1.23222C0.591937 12.654 0.591937 11.6808 1.23222 11.6808H6.56261C6.78524 10.5706 7.75451 9.73434 8.91558 9.73434C10.0767 9.73434 11.0459 10.5706 11.2686 11.6808H18.5198C19.1601 11.6808 19.1601 12.654 18.5198 12.654ZM7.475 12.1654C7.475 11.3597 8.12054 10.7055 8.91563 10.7055C9.71072 10.7055 10.3563 11.3597 10.3563 12.1654C10.3563 12.9711 9.71072 13.6252 8.91563 13.6252C8.12054 13.6252 7.475 12.9711 7.475 12.1654ZM14.6781 5.84061C13.883 5.84061 13.2375 6.49475 13.2375 7.30044C13.2375 8.10614 13.883 8.76028 14.6781 8.76028C15.4732 8.76028 16.1188 8.10614 16.1188 7.30044C16.1188 6.49475 15.4732 5.84061 14.6781 5.84061ZM3.63333 2.43105C3.63333 1.62535 4.27887 0.971207 5.07396 0.971207C5.86905 0.971207 6.51459 1.62535 6.51459 2.43105C6.51459 3.23674 5.86905 3.89089 5.07396 3.89089C4.27887 3.89089 3.63333 3.23674 3.63333 2.43105Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
}
