import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "../../../plugins/axios";
import BillingCodeBlock from "../../CardBlock";
import { Modal, SquarePlusIcon } from "djinn-components";
import AddPaymentMethodModal from "../../Modals/PaymentMethods/Create";
import EditPaymentMethodModal from "../../Modals/PaymentMethods/Edit";
import { PaymentMethodModel } from "../../../types/models";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");

const BillingTabsPaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodModel[]>(
    []
  );

  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] =
    useState<PaymentMethodModel | null>(null);

  const getPaymentMethods = async () => {
    const { data } = await axios.get("account/payment-methods");
    setPaymentMethods(data);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <>
        <div className="mt-10 grid grid-cols-3 gap-4">
          {paymentMethods.map((pm, index) => (
            <BillingCodeBlock
              card={pm}
              onClick={() => {
                setEditPaymentMethod(pm);
              }}
              key={index}
            />
          ))}
          <div
            className="border bg-gray-600 cursor-pointer rounded-lg border-gray-500 p-4 shadow hover:border-white mb-4 transition ease-in-out duration-150 text-center flex flex-col items-center justify-center text-white"
            onClick={() => setAddPaymentMethod(true)}
          >
            <div className="mx-auto flex items-center justify-center h-10 w-10">
              <SquarePlusIcon />
            </div>
            {/* <h4 className="text-sm">Add New</h4> */}
          </div>
        </div>

        <Modal
          shown={addPaymentMethod}
          dismissible={false}
          onClose={() => setAddPaymentMethod(false)}
        >
          <AddPaymentMethodModal
            onClose={() => setAddPaymentMethod(false)}
            onComplete={async () => {
              await getPaymentMethods();
              setAddPaymentMethod(false);
            }}
          />
        </Modal>

        <Modal
          shown={editPaymentMethod != null}
          dismissible={false}
          onClose={() => setEditPaymentMethod(null)}
        >
          <EditPaymentMethodModal
            paymentMethod={editPaymentMethod as PaymentMethodModel}
            onClose={() => {
              setEditPaymentMethod(null);
              getPaymentMethods();
            }}
          />
        </Modal>
      </>
    </Elements>
  );
};

export default BillingTabsPaymentMethods;
