import { useProjectState } from "./requiresProject";

const useFeatureCheck = () => {
  const { project } = useProjectState();
  /**
   *
   * @param string feature flag that we are checking against
   */
  const checkFeaure = (feature = "") =>
    project.project.features.includes(feature);

  return checkFeaure;
};

export default useFeatureCheck;
