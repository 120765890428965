import React from "react";
import MenuItemEndpoint from "./Endpoint";
import MenuItemGroup from "./Group";
import MenuItemContentPage from "./ContentPage";
const MenuItem = (props: any) => {
  switch (props.type) {
    case "group":
      return <MenuItemGroup {...props} />;
    case "endpoint":
      return <MenuItemEndpoint {...props} />;
    case "page":
      return <MenuItemContentPage {...props} />;
    default:
      return <div>Menu Type not set</div>;
  }
};

export default MenuItem;
