import React from "react";

export default function YesNoField({ value = false, onChange }: any) {
  return (
    <>
      <div
        className="w-full rounded-full flex bg-gray-500 items-center cursor-pointer"
        onClick={() => onChange(!value)}
      >
        <div
          className={` rounded-full w-2/4 text-center p-3 ${
            value == true ? "bg-black" : "bg-transparent"
          }`}
        >
          Yes
        </div>
        <div
          className={` rounded-full w-2/4 text-center p-3 ${
            value == false ? "bg-black" : "bg-transparent"
          }`}
        >
          No
        </div>
      </div>
    </>
  );
}
