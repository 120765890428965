import { LoadingIcon } from "djinn-components";
import React, { useCallback, useEffect, useState } from "react";
import { FiRefreshCcw } from "react-icons/fi";
import { FcApproval } from "react-icons/fc";
import { ProjectParentType } from "../../types/models";
import axios from "../../plugins/axios";

interface CustomDomainConfigProps {
  project: ProjectParentType;
}

const CustomDomainConfig: React.FC<CustomDomainConfigProps> = ({ project }) => {
  const [loading, setLoading] = useState(false);
  const [setup, setSetup] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errored, setErrored] = useState(false);
  const [accessRequestSent, setAccessRequestSent] = useState(false);
  const [dnsOptions, setDNSOptions] = useState<any>(null);
  const [apiMessage, setApiMessage] = useState("");

  const fetchStatus = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`projects/${project.id}/domain/check`);
      if (data.dns_values) {
        setDNSOptions(data.dns_values);
      } else {
        setDNSOptions(null);
      }
      setErrored(data.error);
      setSetup(data.configured);
      setApiMessage(data.message);
      setFailed(false);
    } catch (error) {
      setFailed(true);
    }
    setLoading(false);
  }, [project]);

  useEffect(() => {
    fetchStatus();
  }, [project, fetchStatus]);

  const requestAccess = async () => {
    setLoading(true);
    try {
      await axios.post(`projects/${project.id}/domain/request-delegate`);
      setAccessRequestSent(true);
    } catch (error) {}
    setLoading(false);
  };

  if (loading)
    return (
      <div className="p-4 rounded-full bg-gray-850 bg-opacity-50 text-sm font-light mt-4 text-gray-300 flex items-center justify-between">
        <span>Loading domain status....</span> <LoadingIcon size={20} />
      </div>
    );

  if (failed)
    return (
      <div className="p-4 rounded-full bg-gray-850 bg-opacity-50 text-sm font-light mt-4 text-gray-300 flex items-center justify-between">
        <span>Failed to load domain status</span>{" "}
        <button
          className="cursor-pointer text-gray-500 hover:text-white"
          onClick={fetchStatus}
        >
          <FiRefreshCcw size={20} />
        </button>
      </div>
    );
  if (setup)
    return (
      <div className="p-4 rounded-full bg-gray-850 bg-opacity-50 text-sm font-light mt-4 text-gray-300 flex items-center justify-between">
        <span>Domain Configuration Valid</span> <FcApproval size={24} />
      </div>
    );

  if (errored && apiMessage == "request_delegation")
    return (
      <div className="p-4 rounded-full bg-gray-850 bg-opacity-50 text-sm font-light mt-4 text-gray-300 flex items-center justify-between">
        {accessRequestSent ? (
          <>
            <span>Please check your email for a authorisation request</span>{" "}
            <button
              className="cursor-pointer text-gray-500 hover:text-white"
              onClick={fetchStatus}
            >
              <FiRefreshCcw size={20} />
            </button>
          </>
        ) : (
          <>
            <span>
              Your domain has already been assigned to another vercel account,
              you request
            </span>{" "}
            <button
              className="cursor-pointer text-gray-500 hover:text-white"
              onClick={requestAccess}
            >
              REQUEST ACCESS
            </button>
          </>
        )}
      </div>
    );
  return (
    <div className="bg-gray-500 rounded-3xl">
      <div className="p-4 rounded-3xl bg-gray-850 bg-opacity-50 text-sm font-light mt-4 text-gray-300 flex items-center justify-between">
        <span className="text-red-400 font-medium">Invalid Configuration </span>{" "}
        <button
          className="cursor-pointer text-gray-500 hover:text-white"
          onClick={fetchStatus}
        >
          <FiRefreshCcw size={20} />
        </button>
      </div>
      {dnsOptions && (
        <div className="p-4 text-left">
          <p className="text-xs mb-2">
            Please add the following DNS record to your domain:
          </p>
          <table>
            <tr>
              <th className="w-1/3">Type</th>
              <th className="w-1/3">Name</th>
              <th className="w-1/3">Value</th>
            </tr>
            {dnsOptions.is_apex ? (
              <tr>
                <td className="font-light text-gray-300 opacity-75">A</td>
                <td className="font-light text-gray-300 opacity-75">@</td>
                <td className="font-light text-gray-300 opacity-75">
                  76.76.21.21
                </td>
              </tr>
            ) : (
              <tr>
                <td className="font-light text-gray-300 opacity-75">CNAME</td>
                <td className="font-light text-gray-300 opacity-75">
                  {dnsOptions.txt_value}
                </td>
                <td className="font-light text-gray-300 opacity-75">
                  cname.docbloc.co
                </td>
              </tr>
            )}
          </table>
        </div>
      )}
    </div>
  );
};

export default CustomDomainConfig;
