import React from "react";
import { Controller } from "react-hook-form";
import { errorStyle } from "../../../../constants/styles";
import ParameterField from "../../../Inputs/ParameterField";

export default function ProjectEditTabParameters({ control, errors }: any) {
  return (
    <div className="mt-8 rounded-3xl ">
      <Controller
        name="parameters"
        control={control}
        render={({ field: { onChange, value } }) => (
          <ParameterField parameters={value} updateParams={onChange} />
        )}
      />
      {errors.parameters && errors.parameters.type == "api" && (
        <span className={errorStyle}>{errors.parameters.message}</span>
      )}
    </div>
  );
}
