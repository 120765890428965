import React, { useEffect, useState } from "react";
import axios from "../../../plugins/axios";
import { strFromSlug } from "../../../utils/strings.utils";

const PermissionsSelectField: React.FC<{
  value: string[];
  onChange: (v: string[]) => void;
  invalid: boolean;
}> = ({ value, onChange }) => {
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getList = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get("/permissions");
      setList(data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div>
      {Object.keys(list).map((group, index) => (
        <div key={index}>
          <h3 className="text-gray-200 font-medium mb-2">
            {strFromSlug(group)}
          </h3>
          <div>
            {loading && <div>Loading...</div>}
            {Object.keys(list[group]).map((ii, index2) => (
              <div
                key={index2}
                className={`inline-block  rounded-md mr-2 mb-2 py-1 px-2 text-xs cursor-pointer  ${
                  value.includes(`${group}.${ii}`)
                    ? "bg-primary text-gray-250 hover:bg-primary-dark"
                    : "bg-gray-450 hover:bg-gray-500"
                }`}
                onClick={() => {
                  if (value.includes(`${group}.${ii}`)) {
                    onChange(value.filter((vv) => vv != `${group}.${ii}`));
                  } else {
                    onChange([...value, `${group}.${ii}`]);
                  }
                }}
              >
                {strFromSlug(ii)}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PermissionsSelectField;
