import { LoadingIcon } from "djinn-components";
import React from "react";

const LoadingComponent: React.FC<any> = () => {
  return (
    <div className="w-full h-screen flex items-end justify-center pb-10 bg-gray-800 bg-opacity-50 fixed top-0 left-0">
      <LoadingIcon size={30} color="#12A768" />
    </div>
  );
};

export default LoadingComponent;
