import React from "react";

interface HeaderProps {
  title: string;
  subtitle: string;
  showUser?: boolean;
}
export default function Header({ title, subtitle }: HeaderProps) {
  return (
    <div className="max-w-5xl mx-auto flex justify-between mt-10">
      <div>
        <h2 className="text-2xl leading-6 font-regular text-white">{title}</h2>
        <p className="text-xs text-gray-400 mt-1">{subtitle}</p>
      </div>
    </div>
  );
}
