/* eslint import/no-named-as-default-member: "off" */
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { AiOutlineWarning } from "react-icons/ai";

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const useConfirmDelete = (
  title = "Confirm Delete",
  message = "Are you sure you want to delete this item? The action cannot be undone."
) => {
  const cancelDelete = () => {
    ReactDOM.render(<div />, document.getElementById("confirm-delete-popup"));
  };

  const [loading, setLoading] = useState(false);

  const showTheConfirm = (
    match = "DELETE",
    callback = async () => {
      await sleep(1000);
    }
  ) => {
    const deleteModal = (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-end justify-start min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-50" />
          </div>
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span className="hidden">{match}</span>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
          &#8203;
          <div
            className="inline-block align-top bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 text-white">
                <AiOutlineWarning size={20} />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  className="text-lg leading-6 font-medium text-white"
                  id="modal-headline"
                >
                  {title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm leading-5 text-gray-300">{message}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 ml-4 sm:flex">
              <span className="flex w-full rounded-md shadow-lg sm:mt-0">
                <button
                  type="button"
                  onClick={cancelDelete}
                  className="inline-flex justify-center w-full rounded-full border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:bg-red-100"
                >
                  Cancel
                </button>
              </span>
              <span className="ml-3 flex w-full rounded-md shadow-lg">
                <button
                  type="button"
                  onClick={async () => {
                    setLoading(true);
                    await callback();
                    setLoading(false);
                    cancelDelete();
                  }}
                  disabled={loading}
                  className={`inline-flex justify-center w-full rounded-full border border-transparent px-4 py-2 ${
                    loading ? "bg-red-100" : "bg-red-700"
                  } text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                >
                  Confirm
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    );

    ReactDOM.render(
      deleteModal,
      document.getElementById("confirm-delete-popup")
    );
  };

  return showTheConfirm;
};

export default useConfirmDelete;
