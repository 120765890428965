import React, { useState } from "react";

interface TabsProps {
  tabs: {
    title: string;
    component: any;
  }[];
  defaultActive?: number;
}
const Tabs: React.FC<TabsProps> = ({ tabs, defaultActive = 0 }) => {
  const [active, setActive] = useState(defaultActive);

  const activeStyle =
    "whitespace-nowrap px-8 py-3 text-xs bg-green-600 rounded-full text-white pointer cursor-pointer";

  const inactiveStyle =
    "whitespace-nowrap px-8 py-3 text-xs text-gray-400 cursor-pointer hover:text-white";

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex items-center justify-center">
        <div className="bg-gray-500 rounded-full flex mt-1 max-w-min">
          {tabs.map((tab, index) => (
            <div
              className={active == index ? activeStyle : inactiveStyle}
              onClick={() => setActive(index)}
              key={index}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
      {tabs.map((tab, index) => (
        <div hidden={active != index} key={index}>
          {tab.component}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
