import React from "react";
import { useParams } from "react-router-dom";
import { Button } from "djinn-components";

const h1Style = "text-2xl font-medium mb-2";
const pStyle = "text-left text-gray-450 text-sm mb-10";

export default function ErrorScreen() {
  const params = useParams<{ type: string }>();
  const DefaultComp = ({ title, subtitle, buttnText, url }: any) => {
    return (
      <>
        <h1 className={h1Style}>{title}</h1>
        <p className={pStyle}>{subtitle}</p>
        <div className="flex items-center justify-start">
          <div className="w-44 mr-4">
            <Button
              onClick={() => (window.location = url)}
              buttonStyle="green"
              text={buttnText}
            />
          </div>
          <a
            href="https://docbloc.statuspage.io"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-450 text-sm font-medium hover:text-white"
          >
            Status page
          </a>
        </div>
      </>
    );
  };

  const getContent = () => {
    switch (params.type) {
      case "503":
        return (
          <DefaultComp
            title="Currently Unavailable"
            subtitle="We are currently performing scheduled maintenance, sorry for any inconvenience this may cause. We will be back soon."
            buttnText="Refresh"
            url="/"
          />
        );
      case "404":
        return (
          <DefaultComp
            title="Not Found"
            subtitle="Looks like the page you are looking for doesn't exist, double check the url or click the link below to return to the dashboard."
            buttnText="Back to dashboard"
            url="/"
          />
        );
      default:
        return (
          <DefaultComp
            title="Error"
            subtitle="Sorry something unexpected has happened. Please visit our status page link below to find out if there as an outage or if the problem persists please visit the support center to contact us."
            buttnText="Back to dashboard"
            url="/choose-project"
          />
        );
    }
  };

  return (
    <div className="w-screen h-screen bg-gray-800 text-white flex justify-between">
      <div className="bg-gray-600 h-screen w-2/4 bg-opacity-50 rounded-r-2xl shadow-lg flex justify-center items-center flex-col">
        <div className="w-1/2 text-left">{getContent()}</div>
      </div>
      <div className="h-screen w-2/4 flex justify-center items-center">
        <img
          src="/images/maintenance-mode.svg"
          alt="Maintenance mode graphic"
          className="max-w-sm w-full"
        />
      </div>
    </div>
  );
}
