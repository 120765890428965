import React from "react";

import { CheckboxInput } from "djinn-components";

export default function ThemePick() {
  return (
    <div
      className="w-full h-full flex justify-center items-center text-white"
      style={{ height: "500px" }}
    >
      <label className="rounded-3xl bg-gray-700 px-6 py-8 mr-4 flex flex-col justify-center items-center cursor-pointer hover:bg-opacity-75 transition ease-in-out duration-150">
        <h5>Dark Mode</h5>
        <img src="/images/dark-mode.svg" alt="dark-mode-theme" />
        <CheckboxInput id="dark_mode" value={true} onChange={() => {}} />
      </label>
      <label className="rounded-3xl px-6 py-8 ml-4 flex flex-col justify-center items-center cursor-not-allowed">
        <h5>Light Mode (Coming Soon)</h5>

        <img src="/images/light-mode.svg" alt="light-mode-theme" />
        <CheckboxInput id="light_mode" value={false} onChange={() => {}} />
      </label>
    </div>
  );
}
