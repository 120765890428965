import dateFormat from "dateformat";
import React, { useEffect, useState } from "react";
import axios from "../../../plugins/axios";
import { InvoiceModel } from "../../../types/models";

const BillingTabsHistory: React.FC<any> = () => {
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [invoicesLoading, setInvoicesLoading] = useState(true);

  const getInvoices = async () => {
    const { data } = await axios.get("account/invoices");
    setInvoices(data);
    setInvoicesLoading(false);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  return (
    <div className="w-full mt-10">
      <table
        className="border-separate block"
        style={{ borderSpacing: "0 1.2rem" }}
      >
        <thead className="opacity-100 bg-opacity-100 block w-full">
          <tr className="table pl-6">
            <td
              className="bg-gray-600 text-left text-md leading-4 font-large text-white tracking-wider sticky opacity-100 bg-opacity-100"
              width="35%"
            >
              Invoice #
            </td>
            <td
              className="bg-gray-600 text-left text-md leading-4 font-large text-white tracking-wider sticky"
              width="30%"
            >
              Date
            </td>
            <td
              className="bg-gray-600 text-left text-md leading-4 font-large text-white tracking-wider sticky"
              width="30%"
            >
              Total
            </td>
            <td
              className="px-6 bg-gray-600 lg:w-10 w-12 sticky"
              width="5%"
            ></td>
          </tr>
        </thead>
        <tbody className="block max-h-124 overflow-auto">
          {invoices.map((invoice, index) => (
            <tr
              className={
                "bg-gray-700 bg-opacity-50 rounded-full overflow-hidden table w-full mb-6 h-12 pl-6"
              }
              key={index}
            >
              <td
                className="whitespace-no-wrap rounded-l-full text-sm leading-5 font-regular text-white opacity-75 "
                width="35%"
              >
                #{invoice.number}
              </td>
              <td
                className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 "
                width="30%"
              >
                {dateFormat(invoice.created, "d mmm yyyy")}
              </td>
              <td
                className="whitespace-no-wrap  text-sm font-light leading-5 text-white opacity-50 "
                width="28%"
              >
                £{invoice.total / 100}
              </td>
              <td width="7%">
                <a
                  href={invoice.hosted_invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block h-5 w-10 cursor-pointer text-gray-400 hover:text-white text-sm font-bold"
                >
                  VIEW
                </a>
              </td>
            </tr>
          ))}

          {invoicesLoading && (
            <tr>
              <td className="p-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-400">
                Loading Invoices...
              </td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
              <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
              <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BillingTabsHistory;
