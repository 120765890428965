import React from "react";

export default function FolderWithCount({ className = "", count = 0 }) {
  return (
    <span className={className}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0021 1.88875H8.40041L7.49226 0.773704C7.06089 0.295828 6.44788 0 5.78947 0H1.97523C0.885449 0 0 0.887484 0 1.97977V16.0202C0 17.1125 0.885449 18 1.97523 18H20.0248C21.1145 18 22 17.1125 22 16.0202V3.84576C21.9773 2.75348 21.0918 1.88875 20.0021 1.88875ZM1.35049 1.97977C1.35049 1.52465 1.49845 1.1378 1.97523 1.1378H5.78947C6.10733 1.1378 6.42518 1.27434 6.62952 1.5019L7.85552 3.00379H20.0475C20.5015 3.00379 20.7778 3.37927 20.7778 3.85714V16.0714C20.7778 16.5265 20.0323 16.7143 19.5556 16.7143L1.97523 16.4507C1.52116 16.4507 1.31933 17.06 1.31933 16.5821C2.54156 16.5821 1.3732 1.97977 1.35049 1.97977Z"
          fill="#DADADA"
        />
        <path
          d="M1.22222 2.57143H7.61199H20.7778V16.7143L1.22222 17.3571V2.57143Z"
          fill="#DADADA"
        />
        <path
          d="M1.22222 0.642857H7.33333L7.94444 3.21429H0.611111L1.22222 1.92857V0.642857Z"
          fill="#DADADA"
        />
        {count < 10 && (
          <text
            fill="#1F1F26"
            style={{ whiteSpace: "pre" }}
            fontFamily="Avenir"
            fontSize="10"
            fontWeight="800"
            letterSpacing="0px"
          >
            <tspan x="7.69995" y="13.2288">
              {count}
            </tspan>
          </text>
        )}

        {count > 10 && count <= 99 && (
          <text
            fill="#1F1F26"
            style={{ whiteSpace: "pre" }}
            fontFamily="Avenir"
            fontSize="10"
            fontWeight="800"
            letterSpacing="0px"
          >
            <tspan x="5" y="13.17">
              {count}
            </tspan>
          </text>
        )}
        {count > 99 && (
          <>
            <text
              fill="#1F1F26"
              style={{ whiteSpace: "pre" }}
              fontFamily="Avenir"
              fontSize="10"
              fontWeight="800"
              letterSpacing="0px"
            >
              <tspan x="1.69995" y="13.4649">
                99
              </tspan>
            </text>
            <text
              fill="#1F1F26"
              style={{ whiteSpace: "pre" }}
              fontFamily="Avenir"
              fontSize="8"
              fontWeight="800"
              letterSpacing="0px"
            >
              <tspan x="14" y="13.036">
                +
              </tspan>
            </text>
          </>
        )}
      </svg>
    </span>
  );
}
