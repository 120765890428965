import React from "react";
import { AvatarPlaceholder, PlusIcon } from "djinn-components";
import { Link } from "react-router-dom";
import { useProjectState } from "../../utils/requiresProject";

const ProjectDashboardUsers = () => {
  const { project } = useProjectState();
  const users = project.project.users;

  return (
    <div className="bg-gray-600 bg-opacity-50 h-full w-full rounded-xl flex justify-start items-start flex-col overflow-auto p-10">
      <div className="flex justify-between items-center w-full mb-2">
        <h5 className="text-white font-medium text-lg">Project Users</h5>
        <Link to={`/project/${project.id}/settings/iam/users?add=1`}>
          <a className="block w-5 h-5  group cursor-pointer">
            <PlusIcon size={16} className="group-hover:text-gray-300" />
          </a>
        </Link>
      </div>
      <div className="w-full">
        {users.map((user, index) => (
          <div
            key={index}
            className="flex justify-between items-center py-4 border-b border-gray-600 w-full"
          >
            <div className="flex items-center text-sm">
              {user.avatar ? (
                <img
                  src={user.avatar}
                  alt={user.name}
                  className="w-8 h-8 rounded-full mr-4"
                />
              ) : (
                <AvatarPlaceholder
                  className="w-8 h-8 rounded-full mr-4 text-sm"
                  text={user.name}
                />
              )}
              {user.name}
            </div>
            <span className="text-gray-450 capitalize text-sm">
              {user.role}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectDashboardUsers;
